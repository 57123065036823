import { useState } from 'react'
import { useSelector } from 'react-redux'

import { selectTelematicsTenantSourcesAsArray } from '@/features/domain/account'
import { selectGpsTrackingProvider, selectUserConfiguration } from '@/features/domain/user'
import { selectTelematicsDevices } from '@/features/domain/device'
import { selectGpsTags } from '@/features/domain/tags'

import { computeWwGpsFormInitialValue } from './wwgps/computeWwGpsFormInitialValue'
import { computeTelematicsFormInitialValue } from './telematics/computeTelematicsFormInitialValue'

export function useFormInitialValue(unifiedVehicle: uui.domain.client.UnifiedVehicle) {
  const trackingProvider = useSelector(selectGpsTrackingProvider)
  const devices = useSelector(selectTelematicsDevices)
  const userConfig = useSelector(selectUserConfiguration)
  const allGpsTags = useSelector(selectGpsTags)
  const tenantSoruces = useSelector(selectTelematicsTenantSourcesAsArray)

  if (trackingProvider === 'none') {
    throw new Error('Invalid tracking provider')
  }

  return useState(() => {
    switch (trackingProvider) {
      case 'WWGPS':
        return computeWwGpsFormInitialValue(unifiedVehicle, userConfig, allGpsTags)

      case 'telematics':
        return computeTelematicsFormInitialValue(unifiedVehicle, devices, tenantSoruces)
    }
  })
}
