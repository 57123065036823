import type { RenderItemProps } from '@/components/StructuredVirtualList'

import { memo, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { NearMe, Tag, NearMeDisabled } from '@mui/icons-material'

import { selectGpsTrackingProvider } from '@/features/domain/user'

import { selectTelematicsDevices } from '@/features/domain/device'
import { getColorValue, theme } from '@/styles'
import { TELEMATICS_DUMMY_GPS_MOBILE_DEVICE_ID } from '@/server-data'
import { useIsSimulation } from '@/hooks'
import {
  ListItem,
  ListItemRow,
  ListItemTitle,
  ListItemContent,
  ListItemAvatarRounded,
  ListItemVisibilityToggler,
} from '@/components/List'
import { selectTelematicsTenantSourcesAsArray } from '@/features/domain/account'

import { getLabel } from '../../../utils/getLabel'

import { useVisibility } from '../hooks/useVisibility'
import { useTexts } from '../hooks/useTexts'

import { Tags } from './Tags'

type Props = Extract<RenderItemProps<'unifiedVehicles'>, { type: 'item' }>

export const VehicleItem = memo((props: Props) => {
  const { selected, item, onItemClick, onItemDoubleClick } = props

  const [visibleInList, toggleVisibility] = useVisibility(item.unifiedId)
  const trackingProvider = useSelector(selectGpsTrackingProvider)
  const devices = useSelector(selectTelematicsDevices)
  const tenantSources = useSelector(selectTelematicsTenantSourcesAsArray)
  const isSimulation = useIsSimulation()
  const texts = useTexts()

  const onDoubleClick = useCallback(() => onItemDoubleClick?.(item), [onItemDoubleClick, item])
  const onClick = useCallback(event => onItemClick({ item, event }), [onItemClick, item])

  // Sort tags by value
  const tags = useMemo(
    () =>
      [...(item.hasRoutingLicense ? item.tags : item.allowGps ? item.gpsTags : [])].sort((t1, t2) =>
        t1.value.localeCompare(t2.value),
      ),
    [item],
  )

  const deviceId = item.hasRoutingLicense ? (item.vehicle.gpsDeviceId ?? '') : ''

  const tracked = Boolean(deviceId)

  const telematicsDevice =
    trackingProvider === 'telematics'
      ? deviceId === TELEMATICS_DUMMY_GPS_MOBILE_DEVICE_ID
        ? undefined
        : devices[deviceId]
      : undefined

  const tenatSource = tenantSources.find(ts => ts.id === telematicsDevice?.tenantSourceId)

  // Items in simulation mode are always visible
  const visible = isSimulation || visibleInList

  const allowGps = item.allowGps
  const virtual = !item.isPhysicalDevice

  const avatarText = item.hasRoutingLicense ? (item.vehicle.idx !== -1 ? item.vehicle.idx : '') : ''
  const backgroundColor = item.hasRoutingLicense
    ? getColorValue(item.vehicle.color)
    : theme.colors.$pureWhite

  const noTags = tags.length === 0

  return (
    <ListItem
      invisibleOnMap={!visible}
      onDoubleClick={onDoubleClick}
      selected={selected}
      onClick={onClick}
      testId="vehicle-list-item"
      avatar={<ListItemAvatarRounded color={backgroundColor}>{avatarText}</ListItemAvatarRounded>}
      right={
        isSimulation ? null : (
          <ListItemVisibilityToggler toggleVisibility={toggleVisibility} visible={visible} />
        )
      }
    >
      <ListItemTitle>{getLabel(item)}</ListItemTitle>

      <ListItemRow testId="vehicle-tracking-row">
        <ListItemContent icon={tracked ? <NearMe /> : <NearMeDisabled />}>
          {trackingProvider === 'telematics'
            ? texts.deviceTelematics(deviceId, tenatSource, tracked, isSimulation)
            : texts.device(
                allowGps,
                virtual,
                item.hasRoutingLicense,
                isSimulation,
                trackingProvider,
              )}
        </ListItemContent>
      </ListItemRow>

      <ListItemRow testId="vehicle-tags-row">
        <ListItemContent icon={<Tag />} empty={noTags}>
          {noTags ? '-' : <Tags tags={tags} />}
        </ListItemContent>
      </ListItemRow>
    </ListItem>
  )
})

VehicleItem.displayName = 'VehicleItem'
