import { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Dialog, IconButton, Stack, Typography } from '@mui/material'
import { Close } from '@mui/icons-material'

import {
  selectTelematicsTenantSourcesWithoutMobileAsArray,
  selectTelematicsSourcesAsArray,
} from '@/features/domain/account'
import { refreshGpsData } from '@/features/domain/gps'
import { useAppDispatch } from '@/store'
import { ViewContainer } from '@/formUi'

import { useCreateTenantSourceModal } from '../../modals/CreateTenantSourceModal'
import { useCanIntegrate } from '../../hooks/useCanIntegrate'
import { useTexts } from '../../hooks/useTexts'

import { AvailableIntegrationsGrid } from './components/AvailableIntegrationsGrid'
import { DiscoverIntegrationsGrid } from './components/DiscoverIntegrationsGrid'
import { ActiveIntegrationsGrid } from './components/ActiveIntegrationsGrid'
import { UnavailableBanner } from './components/UnavailableBanner'
import { useDialog } from './hooks/useDialog'

type Props = {
  setIntegrationId: (integrationId: string | undefined) => void
}

export function Main(props: Props) {
  const { setIntegrationId } = props

  const { canIntegrate, trackingProvider, userType, userRole } = useCanIntegrate()
  const { open, onCloseDialog, onShowDialog } = useDialog()
  const [tenantSource, setTenantSource] = useState<
    uui.domain.server.gps.telematics.Source | undefined
  >()

  const { show: showCreateTenantSourceModal, Modal: CreateTenantSourceModal } =
    useCreateTenantSourceModal()

  const telematicsTenantSources = useSelector(selectTelematicsTenantSourcesWithoutMobileAsArray)

  const telematicsSources = useSelector(selectTelematicsSourcesAsArray)
  const firstRender = useRef(true)
  const dispatch = useAppDispatch()
  const texts = useTexts()

  const availableIntegrationsMode = telematicsTenantSources.length === 0 ? 'main' : 'discover'
  const showUnavailableBanner = !canIntegrate

  useEffect(() => {
    if (!firstRender.current) return
    dispatch(refreshGpsData({ tenantSources: true, deviceInfos: true, sources: true }))
    firstRender.current = false
  }, [dispatch])

  return (
    <ViewContainer width="100%" testid="integrations-view">
      <Stack direction="row" width="100%" justifyContent="center" paddingX={4}>
        <Stack spacing={5} width="100%" maxWidth={1152}>
          <Stack spacing={5}>
            <Stack>
              <Typography variant="h6">{texts.headerTitle}</Typography>

              {availableIntegrationsMode === 'main' && (
                <Typography variant="caption">{texts.headerDescription}</Typography>
              )}
            </Stack>

            {showUnavailableBanner && (
              <UnavailableBanner userType={userType} trackingProvider={trackingProvider} />
            )}

            {availableIntegrationsMode === 'main' ? (
              <AvailableIntegrationsGrid
                availableIntegrations={telematicsSources}
                disabled={showUnavailableBanner}
                onIntegrationClick={() => {
                  showCreateTenantSourceModal()
                }}
                onSetSource={setTenantSource}
                userRole={userRole}
              />
            ) : (
              <DiscoverIntegrationsGrid
                availableIntegrations={telematicsSources}
                activeIntegrations={telematicsTenantSources}
                onShowDialog={onShowDialog}
                onIntegrationClick={() => {
                  showCreateTenantSourceModal()
                }}
                onSetSource={setTenantSource}
                userRole={userRole}
              />
            )}
          </Stack>

          {telematicsTenantSources.length > 0 ? (
            <Stack>
              <Typography variant="h7">{texts.activeIntegrationsTitle}</Typography>
              <ActiveIntegrationsGrid
                activeIntegrations={telematicsTenantSources}
                setIntegrationId={setIntegrationId}
                userRole={userRole}
              />
            </Stack>
          ) : null}
        </Stack>
      </Stack>
      <Dialog open={open} onClose={onCloseDialog} fullWidth maxWidth="md">
        <Stack px={6} py={4} gap={2}>
          <Stack direction="row" justifyContent="space-between" width="100%" alignItems="center">
            <Typography variant="h6">{texts.discoverIntegrations}</Typography>
            <IconButton onClick={onCloseDialog}>
              <Close />
            </IconButton>
          </Stack>
          <AvailableIntegrationsGrid
            availableIntegrations={telematicsSources}
            disabled={showUnavailableBanner}
            onIntegrationClick={() => {
              showCreateTenantSourceModal()
              onCloseDialog()
            }}
            onSetSource={setTenantSource}
            userRole={userRole}
          />
        </Stack>
      </Dialog>
      {tenantSource ? <CreateTenantSourceModal source={tenantSource} /> : <></>}
    </ViewContainer>
  )
}
