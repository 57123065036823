import { useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import { Alert, Button, IconButton, Stack, Typography } from '@mui/material'
import { Close, Launch } from '@mui/icons-material'
import { useTexts } from '../../hooks/useTexts'
import { useGoToIntegrationsModal } from '../../modals/GoToIntegrationsModal'
import { usePreventShowAtom } from './atoms/preventShow'

type TelematicsAlertProps = {
  alertParts:
    | readonly ['intro']
    | readonly ['intro', 'add-physical-tracking']
    | readonly ['intro', 'contact-support']
    | undefined
}

export function TelematicsAlert(props: TelematicsAlertProps) {
  const { alertParts } = props
  const [preventShow, setPreventShow] = usePreventShowAtom()

  const onCloseButtonClick = useCallback(() => {
    if (!alertParts) return null

    const alertPartsKey = alertParts.join('-')

    setPreventShow({ [alertPartsKey]: true })
  }, [alertParts, setPreventShow])

  if (!alertParts) return null

  const alertPartsKey = alertParts.join('-')

  if (preventShow[alertPartsKey]) return null

  return (
    <Alert severity="info" sx={{ marginTop: 1, marginBottom: 1 }}>
      <Stack direction="row" gap={2} alignItems="flex-start">
        <Stack gap={0.5}>
          {alertParts.map(part => {
            switch (part) {
              case 'intro':
                return <InfoPart key={part} />
              case 'add-physical-tracking':
                return <AddPhysicalTrackingPart key={part} />
              case 'contact-support':
                return <ContactSupportPart key={part} />
            }
          })}
        </Stack>

        <IconButton onClick={onCloseButtonClick}>
          <Close />
        </IconButton>
      </Stack>
    </Alert>
  )
}

function InfoPart() {
  const texts = useTexts()

  return (
    <>
      <Typography variant="body2" component="span">
        <FormattedMessage
          id={texts.alert.info.text}
          values={{
            b: chunks => (
              <Typography fontWeight={500} display="inline" fontSize="inherit">
                {chunks}
              </Typography>
            ),
          }}
        />
      </Typography>
      <Button
        href="https://workwave.my.site.com/routemanager/s/article/Downloading-the-WorkWave-Route-Manager-Mobile-App"
        target="_blank"
        rel="noopener noreferrer"
      >
        {texts.alert.info.cta} <Launch sx={{ ml: 1 }} />
      </Button>
    </>
  )
}

function AddPhysicalTrackingPart() {
  const texts = useTexts()

  const { Modal: GoToIntegrationsModal, show: showGoToIntegrationsModal } =
    useGoToIntegrationsModal()

  return (
    <>
      <Typography variant="body2" component="span">
        {texts.alert.phisicalTracking.text}
      </Typography>
      <Button onClick={showGoToIntegrationsModal}>{texts.alert.phisicalTracking.cta}</Button>
      <GoToIntegrationsModal />
    </>
  )
}

function ContactSupportPart() {
  const texts = useTexts()

  return (
    <Typography variant="body2" component="span">
      {texts.alert.contactSupport.text}
    </Typography>
  )
}
