import { proxy, subscribe, useSnapshot } from 'valtio'

type PreventShow = {
  intro: boolean
  'intro-add-physical-tracking': boolean
  'intro-contact-support': boolean
}

type PreventShowAtom = { preventShow: PreventShow }

const localStorageId = 'routemanager/v3/atoms/vehicleForm/telematicsAlert/preventShow'

const defaultPreventShow: PreventShow = {
  intro: false,
  'intro-add-physical-tracking': false,
  'intro-contact-support': false,
}

function computeInitialPreventShow() {
  const preferences = JSON.parse(
    localStorage.getItem(localStorageId) ?? JSON.stringify(defaultPreventShow),
  )

  localStorage.setItem(localStorageId, JSON.stringify(preferences))
  return preferences
}

function createDefaultPreventShow() {
  return {
    preventShow: computeInitialPreventShow(),
  }
}

export const preventShowAtom = proxy<PreventShowAtom>(createDefaultPreventShow())

subscribe(preventShowAtom.preventShow, () => {
  localStorage.setItem(localStorageId, JSON.stringify(preventShowAtom.preventShow))
})

// ------------------------------------
// Write functions
// ------------------------------------

type SetPreventShow = (prev: PreventShow) => PreventShow
export type PreventShowSetter = SetPreventShow | Partial<PreventShow>

export const setPreventShow = (valueOrFunc: PreventShowSetter) => {
  // callback with prev value
  if (typeof valueOrFunc === 'function') {
    Object.assign(preventShowAtom.preventShow, valueOrFunc(preventShowAtom.preventShow))
  } else {
    // atomic update
    for (const field of Object.keys(valueOrFunc)) {
      preventShowAtom.preventShow[field] = valueOrFunc[field]
    }
  }

  return preventShowAtom.preventShow
}

// ------------------------------------
// React Hooks
// ------------------------------------

export const usePreventShowAtom = () => {
  return [useSnapshot(preventShowAtom).preventShow, setPreventShow] as const
}
