import { TELEMATICS_DUMMY_GPS_MOBILE_DEVICE_ID } from '@/server-data'
import { computeRmData } from '../utils/computeRmData'

export function computeTelematicsFormInitialValue(
  unifiedVehicle: uui.domain.client.UnifiedVehicle,
  devices: Record<string, uui.domain.client.gps.telematics.DeviceInfo>,
  tenantSources: uui.domain.server.gps.telematics.TenantSource[],
): uui.domain.ui.forms.Rm360VehicleFormValuesWithTelematics {
  if (unifiedVehicle.isPhysicalDevice && unifiedVehicle.trackingProvider !== 'telematics') {
    throw new Error('Invalid tracking provider')
  }

  const mobileTenantSource = tenantSources.find(ts => ts.sourceLabel === 'WWGpsProcessor')

  const rm = computeRmData(unifiedVehicle)

  const device =
    unifiedVehicle.hasRoutingLicense && unifiedVehicle.vehicle.gpsDeviceId
      ? devices[unifiedVehicle.vehicle.gpsDeviceId]
      : undefined

  const gpsDeviceId = unifiedVehicle.hasRoutingLicense ? unifiedVehicle.vehicle.gpsDeviceId : ''

  const tenantSourceId =
    gpsDeviceId === TELEMATICS_DUMMY_GPS_MOBILE_DEVICE_ID
      ? mobileTenantSource?.id ?? ''
      : device?.tenantSourceId ?? ''

  const telematics = {
    gpsTracking: Boolean(gpsDeviceId),
    tenantSourceId,
  }

  return {
    rm,
    telematics,
  }
}
