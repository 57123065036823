import { Divider } from '@mui/material'
import { useSelector } from 'react-redux'

import { FormLayout } from '@/forms-legacy'
import { selectUserConfiguration } from '@/features/domain/user'

import { RmData } from '../../components/RmData'

import { TelematicsTracking } from './components/TelematicsTracking'
import { WwgpsTracking } from './components/WwgpsTracking'

interface Props {
  disableTrackingCheckbox: boolean
  trackingProvider: uui.domain.server.gps.TrackingProvider
  unifiedVehicle: uui.domain.client.UnifiedVehicle
  initialValues:
    | uui.domain.ui.forms.Rm360VehicleFormValues
    | uui.domain.ui.forms.Rm360VehicleFormValuesWithTelematics
  values:
    | uui.domain.ui.forms.Rm360VehicleFormValues
    | uui.domain.ui.forms.Rm360VehicleFormValuesWithTelematics
}

export function Form(props: Props) {
  const { disableTrackingCheckbox } = props

  return (
    <FormLayout>
      <RmData disableTrackingCheckbox={disableTrackingCheckbox} />
      <Divider sx={{ my: 1, width: '100%' }} />

      <TrackingProvider {...props} />
    </FormLayout>
  )
}

function TrackingProvider(props: Props) {
  const { unifiedVehicle, trackingProvider, initialValues, values } = props

  const userConfig = useSelector(selectUserConfiguration)

  const simulation = userConfig.planType === 'simulation'

  if (simulation) {
    return null
  }

  switch (trackingProvider) {
    case 'WWGPS':
      return (
        <WwgpsTracking
          unifiedVehicle={unifiedVehicle}
          values={values as uui.domain.ui.forms.Rm360VehicleFormValues}
        />
      )

    case 'telematics':
      return (
        <TelematicsTracking
          initialValues={initialValues as uui.domain.ui.forms.Rm360VehicleFormValuesWithTelematics}
          values={values as uui.domain.ui.forms.Rm360VehicleFormValuesWithTelematics}
        />
      )

    case 'none':
    default:
      return null
  }
}
