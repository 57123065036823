import { type Calculation } from 'final-form-calculate'
import { TELEMATICS_DUMMY_GPS_MOBILE_DEVICE_ID } from '@/server-data'

function getTenantSourceId(
  isTracked: boolean,
  tenantSources: uui.domain.server.gps.telematics.TenantSource[],
  remainingTelematicsLicenses: number,
) {
  if (!isTracked) return ''

  if (tenantSources.length === 1) {
    return tenantSources[0].id
  }

  if (remainingTelematicsLicenses <= 0) {
    return tenantSources.find(source => source.sourceLabel === 'WWGpsProcessor')?.id ?? ''
  }

  return ''
}

export function createTelematicsDecorators(
  tenantSources: uui.domain.client.gps.telematics.TenantSource[],
  remainingTelematicsLicenses: number,
): Calculation[] {
  return [
    {
      field: 'telematics.gpsTracking',
      updates: (
        isTracked: uui.domain.ui.forms.Rm360VehicleFormValuesWithTelematics['telematics']['gpsTracking'],
        _,
        allValues: uui.domain.ui.forms.Rm360VehicleFormValuesWithTelematics,
        _prevValues: uui.domain.ui.forms.Rm360VehicleFormValuesWithTelematics,
      ) => {
        const tenantSourceId = getTenantSourceId(
          isTracked,
          tenantSources,
          remainingTelematicsLicenses,
        )

        return {
          rm: { ...allValues.rm, deviceId: isTracked ? (allValues.rm?.deviceId ?? '') : '' },
          telematics: {
            ...allValues.telematics,
            tenantSourceId,
          },
        }
      },
    },
    {
      field: 'telematics.tenantSourceId',
      updates: (
        tenantSourceId: uui.domain.ui.forms.Rm360VehicleFormValuesWithTelematics['telematics']['tenantSourceId'],
        _,
        allValues: uui.domain.ui.forms.Rm360VehicleFormValuesWithTelematics,
        prevValues: uui.domain.ui.forms.Rm360VehicleFormValuesWithTelematics,
      ) => {
        if (Object.keys(prevValues).length === 0) return allValues

        const tenantSource = tenantSources.find(source => source.id === tenantSourceId)

        if (tenantSource?.sourceLabel === 'WWGpsProcessor') {
          return {
            rm: { ...allValues.rm, deviceId: TELEMATICS_DUMMY_GPS_MOBILE_DEVICE_ID },
            telematics: allValues.telematics,
          }
        }

        return {
          rm: { ...allValues.rm, deviceId: '' },
          telematics: allValues.telematics,
        }
      },
    },
  ]
}
