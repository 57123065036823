import { PropsWithChildren } from 'react'
import { Stack, Typography } from '@mui/material'

import { useTexts } from '../hooks/useTexts'

export function TrackingHeader(props: PropsWithChildren) {
  const { children } = props
  const texts = useTexts()

  return (
    <Stack direction="row" gap={1} alignItems="start">
      <Stack flexShrink={1}>
        <Typography variant="subtitle2">{texts.trackingHeader.title}</Typography>
        <Typography variant="caption">{texts.trackingHeader.description}</Typography>
      </Stack>
      <Stack flexShrink={0}>{children}</Stack>
    </Stack>
  )
}
