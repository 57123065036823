import { useSelector } from 'react-redux'
import { Typography } from '@mui/material'

import { useIsSimulation } from '@/hooks'
import {
  selectTelematicsDeviceTransientStatuses,
  selectTelematicsTenantSourcesWithoutMobile,
} from '@/features/domain/account'
import {
  ReadonlyLabel,
  ReadonlyBlock,
  ReadonlyField,
  ReadonlyFieldRow,
  ReadonlyFieldLabel,
} from '@/forms-legacy'
import { TELEMATICS_DUMMY_GPS_MOBILE_DEVICE_ID } from '@/server-data'

import { useTexts } from '../hooks/useTexts'

interface Props {
  unifiedVehicle: uui.domain.client.UnifiedVehicle
}

export function TelematicsTrackingSection(props: Props) {
  const { unifiedVehicle } = props

  const deviceTransientStatuses = useSelector(selectTelematicsDeviceTransientStatuses)
  const tenantSources = useSelector(selectTelematicsTenantSourcesWithoutMobile)
  const isSimulation = useIsSimulation()
  const texts = useTexts()

  // Retreive the device id
  const deviceId = unifiedVehicle.hasRoutingLicense ? unifiedVehicle.vehicle.gpsDeviceId ?? '' : ''

  // Check if the vehicle has a device
  const hasDevice = !!deviceId

  // Retreive the physical device
  const device = unifiedVehicle.isPhysicalDevice
    ? (unifiedVehicle.device as uui.domain.client.gps.telematics.DeviceInfo)
    : undefined

  const deviceLabel = device?.deviceLabel ?? device?.deviceId ?? ''

  const tenantSource = device ? tenantSources[device.tenantSourceId] : undefined

  const activationStatus = device
    ? unifiedVehicle.hasRoutingLicense
      ? deviceTransientStatuses[device.deviceId]?.status ?? undefined
      : undefined
    : undefined

  const activationStatusMessage =
    activationStatus === 'ERROR'
      ? texts.somethingWentWrongWithTheDevice
      : activationStatus === 'PENDING-ACTIVATION'
      ? texts.deviceActivationPending
      : undefined

  const activationStatusMessageColor =
    activationStatus === 'ERROR'
      ? 'error'
      : activationStatus === 'PENDING-ACTIVATION'
      ? 'primary'
      : undefined

  if (isSimulation) return null

  if (!deviceId) {
    return (
      <Typography variant="body2" fontWeight={600}>
        {texts.vehicleNotTracked}
      </Typography>
    )
  }

  if (deviceId === TELEMATICS_DUMMY_GPS_MOBILE_DEVICE_ID) {
    return (
      <>
        <Typography variant="body2" fontWeight={600} mb={2}>
          {texts.vehicleTracked}
        </Typography>

        <ReadonlyBlock>
          <ReadonlyLabel>{texts.trackBy}</ReadonlyLabel>
          <ReadonlyField>
            <ReadonlyFieldRow>
              <ReadonlyFieldLabel>{texts.driverApp}</ReadonlyFieldLabel>
            </ReadonlyFieldRow>
          </ReadonlyField>
        </ReadonlyBlock>
      </>
    )
  }

  if (!tenantSource) {
    console.error(`Tenant source not found for deviceId ${deviceId}`)
    return null
  }

  return (
    <>
      <Typography variant="body2" fontWeight={600} mb={2}>
        {texts.vehicleTracked}
      </Typography>

      {tenantSource.statusCode === 2000 && !tenantSource.active ? (
        <ReadonlyBlock>
          <Typography
            color="primary"
            fontWeight={600}
            variant="caption"
            data-testid="setup-vehicle__telematics-device-activation-status"
          >
            {texts.integrationDeleteInProgress}
          </Typography>
        </ReadonlyBlock>
      ) : null}

      <ReadonlyBlock>
        <ReadonlyLabel>{texts.trackBy}</ReadonlyLabel>
        <ReadonlyField>
          <ReadonlyFieldRow>
            <ReadonlyFieldLabel>{tenantSource.label}</ReadonlyFieldLabel>
          </ReadonlyFieldRow>
        </ReadonlyField>
      </ReadonlyBlock>

      {hasDevice && (
        <ReadonlyBlock>
          <ReadonlyLabel>{texts.assignedGpsDevice}</ReadonlyLabel>
          <ReadonlyField>
            <ReadonlyFieldRow>
              <ReadonlyFieldLabel>{deviceLabel}</ReadonlyFieldLabel>
            </ReadonlyFieldRow>
          </ReadonlyField>
        </ReadonlyBlock>
      )}

      {activationStatus && (
        <Typography
          color={activationStatusMessageColor}
          variant="caption"
          fontWeight={600}
          data-testid="setup-vehicle__telematics-device-activation-status"
        >
          {activationStatusMessage}
        </Typography>
      )}
    </>
  )
}
