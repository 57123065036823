import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    trackingHeader: {
      title: translate({ id: 'vehicles.form.generalSettings.telematicsTracking.header.title' }),
      description: translate({
        id: 'vehicles.form.generalSettings.telematicsTracking.header.description',
      }),
    },
    alert: {
      info: {
        text: 'vehicles.form.generalSettings.telematicsTracking.alert.info',
        cta: translate({ id: 'vehicles.form.generalSettings.telematicsTracking.alert.info.cta' }),
      },
      phisicalTracking: {
        text: translate({
          id: 'vehicles.form.generalSettings.telematicsTracking.alert.addPhysicalTracking',
        }),
        cta: translate({
          id: 'vehicles.form.generalSettings.telematicsTracking.alert.addPhysicalTracking.cta',
        }),
      },
      contactSupport: {
        text: translate({
          id: 'vehicles.form.generalSettings.telematicsTracking.alert.contactSupport',
        }),
      },
    },
  }))

  return api
}
