import { useSelector } from 'react-redux'
import { VerticalLayout, Grow } from '@/components/layout'
import { Text } from '@/local/components'
import { selectGpsTrackingProvider } from '@/features/domain/user'
import { Tooltip } from '@/components/primitives/Tooltip'

import { useTexts } from './useTexts'

interface Props {
  drivers: uui.domain.client.rm.ExtendedDriver[]
}

const growStyle = { padding: '2px 0', maxWidth: 260, minWidth: 260, height: '100%' }

export function Details(props: Props) {
  const { drivers } = props

  const trackingProvider = useSelector(selectGpsTrackingProvider)

  const texts = useTexts()

  if (drivers.length !== 1) return null

  const name = drivers[0].driver.name
  const { gpsDeviceId } = drivers[0].driver.deployment
  const isTelematicsUser = gpsDeviceId !== null && trackingProvider === 'telematics'

  // TODO: As from V2 we're currently not providing any last position
  const lastPosition = texts.unknownPosition

  return (
    <Grow
      data-trackid="navigo-drivers-overview-details"
      data-testid="navigo-drivers-overview-details"
      style={growStyle}
      xAxis
    >
      <VerticalLayout justifyContent="space-between">
        <div style={{ overflow: 'hidden', maxWidth: '100%' }}>
          <Tooltip title={name} placement="top">
            <Text
              testid="navigo-drivers-overview-details-name"
              size="$xl"
              weight="$semiBold"
              ellipsis
            >
              {name}
            </Text>
          </Tooltip>
        </div>
        {!isTelematicsUser && (
          <div style={{ overflow: 'hidden', maxWidth: '100%' }}>
            <Tooltip title={lastPosition} placement="top">
              <Text
                testid="navigo-drivers-overview-details-lastPosition"
                size="$h6"
                italic
                ellipsis
              >
                {lastPosition}
              </Text>
            </Tooltip>
          </div>
        )}
      </VerticalLayout>
    </Grow>
  )
}
