import { useSelector } from 'react-redux'
import { useField } from 'react-final-form'
import { TELEMATICS_DUMMY_GPS_MOBILE_DEVICE_ID } from '@/server-data'
import {
  selectTotalTelematicsLicenses,
  selectRemainingTelematicsLicenses,
  selectTelematicsTenantSourcesAsArray,
} from '@/features/domain/account'

export function useOrganizeComponents() {
  const totalTelematicsLicenses = useSelector(selectTotalTelematicsLicenses)
  const remainingTelematicsLicenses = useSelector(selectRemainingTelematicsLicenses)
  const tenantSources = useSelector(selectTelematicsTenantSourcesAsArray)

  const gpsIntegrationAvailable = tenantSources.length > 0

  const deviceIdField = useField<string>('rm.deviceId')
  const telematicsGpsTrackingField = useField<boolean>('telematics.gpsTracking')

  const isSelectingMobileDevice =
    deviceIdField.input.value === TELEMATICS_DUMMY_GPS_MOBILE_DEVICE_ID

  // Tracking disabled
  if (!telematicsGpsTrackingField.input.value) {
    return {
      trackByFieldVisible: false,
    }
  }

  // no licenses
  if (totalTelematicsLicenses === 0) {
    return {
      alertParts: ['intro'] as const,
      trackByFieldVisible: false,
    }
  }

  // licenses but no gps integration
  if (!gpsIntegrationAvailable) {
    return {
      alertParts: ['intro', 'add-physical-tracking'] as const,
      trackByFieldVisible: true,
    }
  }

  // licenses and gps integration but no licenses left
  if (isSelectingMobileDevice) {
    if (remainingTelematicsLicenses <= 0) {
      return {
        alertParts: ['intro', 'contact-support'] as const,
        trackByFieldVisible: true,
      }
    }

    return {
      alertParts: ['intro'] as const,
      trackByFieldVisible: true,
    }
  }

  return {
    alertParts: undefined,
    trackByFieldVisible: true,
  }
}
