import { useState } from 'react'
import { useIntl } from '@/intl'
import { TELEMATICS_DUMMY_GPS_MOBILE_DEVICE_ID } from '@/server-data'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    vehicleLabelTitle: (label: string, deviceId?: string) => {
      if (!deviceId) return `${label} `

      const deviceIdText = translate({
        id: 'gps.trackinglist.list.tooltip.deviceId',
        values: { deviceId },
      })

      return `${label} - ${deviceIdText}`
    },
    deviceTelematics: (
      gpsDeviceId: string,
      tenantSource: uui.domain.server.gps.telematics.TenantSource | undefined,
      tracked: boolean,
      isSimulation: boolean,
    ) => {
      if (isSimulation || !tracked) return translate({ id: 'vehicles.form.tracking.none' })

      if (gpsDeviceId === TELEMATICS_DUMMY_GPS_MOBILE_DEVICE_ID)
        return translate({ id: 'vehicles.form.tracking.tracked' })

      if (tenantSource)
        return translate({
          id: 'vehicles.form.tracking.trackedBy',
          values: { provider: tenantSource.label },
        })

      return translate({ id: 'vehicles.form.tracking.tracked' })
    },

    device: (
      allowGps: boolean,
      virtual: boolean,
      hasVehicle: boolean,
      isSimulation: boolean,
      trackingProvider: uui.domain.server.gps.TrackingProvider,
    ) => {
      if (isSimulation || !allowGps || (trackingProvider === 'telematics' && virtual))
        return translate({ id: 'vehicles.form.tracking.none' })

      if (hasVehicle && virtual) return translate({ id: 'vehicles.form.tracking.mobileApp' })
      if (hasVehicle && !virtual) return translate({ id: 'vehicles.form.tracking.physical' })
      if (virtual) return translate({ id: 'vehicles.form.tracking.onlyDevice.virtual' })

      return translate({ id: 'vehicles.form.tracking.onlyDevice.physical' })
    },

    badges: (tags: uui.domain.client.gps.wwgps.Tag[], hasRoutingLicense: boolean) => {
      const base = translate({ id: hasRoutingLicense ? 'global.noTags' : 'global.noGpsTags' })

      return tags.reduce(
        (acc: string, tag, index: number): string =>
          index === 0 ? tag.value : `${acc}, ${tag.value}`,
        base,
      )
    },

    more: translate({ id: 'global.more' }),
    hideVehicle: translate({ id: 'setup.vehicles.list.item.hideVehicle' }),
    showVehicle: translate({ id: 'setup.vehicles.list.item.showVehicle' }),
  }))

  return api
}
