import { useSelector } from 'react-redux'
import { HorizontalLayout, VerticalLayout, Grow } from '@/components/layout'
import { Text } from '@/local/components'
import { selectLivePositions } from '@/features/domain/device'
import { selectGpsTrackingProvider } from '@/features/domain/user'
import { ConvertDistance } from '@/components/smartUtils/conversion/ConvertDistance'
import { useTexts } from './useTexts'

interface Props {
  drivers: uui.domain.client.rm.ExtendedDriver[]
}

const growStyle = { height: 42 }

export function DriverInfo(props: Props) {
  const { drivers } = props

  const livePositions = useSelector(selectLivePositions)

  const trackingProvider = useSelector(selectGpsTrackingProvider)

  const texts = useTexts()

  if (drivers.length !== 1) return null

  const { gpsDeviceId, email } = drivers[0].driver.deployment

  const isTelematicsUser = gpsDeviceId !== null && trackingProvider === 'telematics'
  const lastPosition = isTelematicsUser ? livePositions[gpsDeviceId] : null

  return (
    <Grow
      data-trackid="navigo-drivers-overview-driverInfo"
      data-testid="navigo-drivers-overview-driverInfo"
      style={growStyle}
      xAxis
    >
      <VerticalLayout justifyContent="space-between">
        {!isTelematicsUser && (
          <HorizontalLayout>
            <Text size="$m">{`${texts.device}:`}&nbsp;</Text>

            <Text
              testid="navigo-drivers-overview-driverInfo-gpsDeviceId"
              weight="$semiBold"
              size="$m"
            >
              {gpsDeviceId ?? texts.notSet}
            </Text>
          </HorizontalLayout>
        )}

        <Text testid="navigo-drivers-overview-driverInfo-email" size="$m">
          {email}
        </Text>

        {isTelematicsUser && lastPosition && (
          <HorizontalLayout alignItems="flex-end">
            <Text size="$m">{`${texts.overallMileage}:`}&nbsp;</Text>

            <Text
              testid="navigo-drivers-overview-driverInfo-overallMileage"
              weight="$bold"
              size="$m"
            >
              <ConvertDistance meters={lastPosition.odometer} />
            </Text>
          </HorizontalLayout>
        )}
      </VerticalLayout>
    </Grow>
  )
}
